import { useEffect, useState } from 'react';
import styles from './css-modules/report_modal.module.css';
import TextField from '@mui/material/TextField';
import { AiFillCloseCircle } from "react-icons/ai";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ReactGA from "react-ga4";
import axios from 'axios';
import { Fade } from '@mui/material';

function ReportModal({ data, openReportModal }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [disableInputs, setDisableInputs] = useState(false);
  const [incompleteErr, setIncompleteErr] = useState(false);
  const [successFul, setSuccessFul] = useState(false);
  const [mailChimpReq, setMailChimpReq] = useState(null);
  const [subscribed, setSubscribed] = useState(false);

  function validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function resetForm() {
    setOrganization('');
    setEmail('');
    setName('');
  }

  function setValueState(event, id) {
    if (id === "name") {
      setName(event.target.value);
    } else if (id === "organization") {
      setOrganization(event.target.value);
    } else if (id === "email") {
      setEmail(event.target.value);
    }
  }

  function submitForm() {
    if (email === "" || organization === "" || name === "") {
      setIncompleteErr(true);
      setSuccessFul(false);
    } else {
      setDisableInputs(true);
      if (validateEmail(email)) {
        let req = {
          email: email,
          name: name,
          organization: organization,
          subscriptionStatus: subscribed ? 'subscribed' : 'unsubscribed',
        };
        setMailChimpReq(req);
      } else {
        setIncompleteErr(true);
        setSuccessFul(false);
      }
    }
  }

  useEffect(() => {
    mailChimpReq && 
      axios
        .post(
          "https://capi-express.herokuapp.com/api/addMemberModalReport",
          mailChimpReq
        )
        .then(function (response) {
          if (response.data.statusCode === 200) {
            setDisableInputs(false);
            setSuccessFul(true);
            setIncompleteErr(false);
            resetForm();
            registerGAEvent();
          } else if (response.data.statusCode === 400) {
            setDisableInputs(false);
            setSuccessFul(true);
            setIncompleteErr(false);
            resetForm();
            registerGAEvent();
          } else {
            setDisableInputs(false);
            setSuccessFul(false);
            setIncompleteErr(true);
          }
        });
  }, [mailChimpReq]);

  function registerGAEvent() {
    ReactGA.event("file_download", {
      'email': email,
      'name': name,
      'org': organization,
      'title': data.title + ' - ' + data.type,
    });
    window.open(data.link);
  }

    return (
      <div className={styles.container}>
          <div className={styles.white_background} onClick={() => openReportModal(false)}></div>
          <div className={styles.modal}>
              <h1>Download Report</h1>
              <div className={styles.report_details}>
                  <p>Details</p>
                  <p>{data.title} - {data.type}</p>
              </div>

              <AiFillCloseCircle className={styles.close_icon} onClick={() => openReportModal(false)}/>

              <div className={styles.form_container}>
                <TextField value={name} disabled={disableInputs} onChange={(event) => setValueState(event, "name")} required label="Name" variant="standard" />
                <TextField value={email} disabled={disableInputs} onChange={(event) => setValueState(event, "email")} required label="Email" variant="standard" />
                <TextField value={organization} disabled={disableInputs} onChange={(event) => setValueState(event, "organization")} required label="Organization" variant="standard" />
              </div>

              <FormControlLabel control={<Checkbox defaultChecked />} label="Receive updates about our future events and projects" />
              <div className={styles.message}>
                {incompleteErr && (
                      <Fade in={true}>
                        <p className={styles.status}>
                          We could not process your request. Please review the information/email address and try again
                        </p>
                      </Fade>
                    )}
                    {successFul && (
                      <Fade in={true}>
                        <p className={styles.status}>
                          Thank you. If the file does not automatically open, <a href={data.link} target="_blank" rel="noopener noreferrer">click here</a>. 
                        </p>
                      </Fade>
                )}
              </div>
              <div  className={'button'} onClick={() => submitForm()}>
                    <div className={'button_animator'}>
                        <div className={'button_animator_inner'}></div>
                    </div>
                    <p className={'button_text'}>Submit</p>
               </div>


              <img className={styles.absolute_logo} alt="" src='https://res.cloudinary.com/capi/image/upload/v1655938657/capi_log_enboci.png'></img>
          </div>
      </div>
    );
  }
  
  export default ReportModal;