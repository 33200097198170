import styles from './css-modules/modal.module.css';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Fade } from '@mui/material';
import { useEffect, useState } from 'react';
import { AiFillCloseCircle } from "react-icons/ai";
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

function TrusteeModal({ data }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [trusteeData, setTrusteeData] = useState(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });
    if (data) {
        let loc = location.pathname.split('/');
        let id = loc[loc.length - 1].replaceAll('trustees_', '');
        let d = data.trustees.filter((dt) => dt.title.toLowerCase().replaceAll(" ", "") === id)[0];
        setTrusteeData(d);
    }
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.overflow = "hidden";
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      document.body.style.overflow = "unset";
      document.body.style.overflowX = "hidden";
    };  
  }, []);

  const meta = {
    title: trusteeData ? trusteeData.title : '',
    description: '',
  };
  
  return (
    <>
      <DocumentMeta {...meta}/>
      <Fade in={true}>
          <div className={styles.modal_container} style={{height: window.innerHeight}}>
              <Link to='/' className={styles.background_container}></Link>
              {trusteeData && <div className={styles.content_container} style={window.innerWidth < 975 ? {minHeight: window.innerHeight, maxHeight: window.innerHeight} : {minHeight: '74vh', maxHeight: '74vh'}}>
                  <div className={styles.image_name}>
                      <div style={{backgroundImage: `url(${trusteeData.trusteeDetails.image.sourceUrl})`}} className={styles.image}></div>
                      <div className={styles.details_container}>
                          <h3 className={styles.name}>{trusteeData.title}</h3>
                          <b className={styles.desigation}>{trusteeData.trusteeDetails.designation}</b>
                          <p className={styles.org}>{trusteeData.trusteeDetails.organization}</p>
                          <p className={styles.country}>{trusteeData.trusteeDetails.country}</p>
                      </div>
                  </div>

                  <div className={styles.desc} dangerouslySetInnerHTML={{__html: trusteeData.trusteeDetails.popupDescription}}></div>
                  <AiFillCloseCircle className={styles.close_icon} onClick={() => navigate(-1)}/>
              </div>}
          </div>
      </Fade>
    </>
  );
}

export default TrusteeModal;
