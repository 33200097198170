import styles from './css-modules/desc_section.module.css';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

function DescSection({ data, orientation, background, buttonData, _ref }) {

    return (
      <div ref={_ref} className={classNames(styles.container, styles[orientation], styles[background])}>
          <img className={styles.banner_image} src={data.data.bannerImage.sourceUrl}></img>
          <div className={styles.text_container}>
              {data.data.title && <h2 className={styles.title}>{data.data.title}</h2>}
              <div className={styles.description} dangerouslySetInnerHTML={{__html: data.data.paragraph}}></div>

              {buttonData && <Link style={{width: 'fit-content', textDecoration: 'none'}} to={buttonData.linkTo}>
                <button className={'button'}>
                  <div className={'button_animator'}>
                      <div className={'button_animator_inner'}></div>
                  </div>
                  <p className={'button_text'}>{buttonData.text}</p>
                </button>
              </Link>}
          </div>
      </div>
    );
  }
  
  export default DescSection;