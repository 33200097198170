import styles from './css-modules/views.module.css';
import PageTitle from '../components/work/page_title.js';
import Projects from '../components/work/projects.js';
import CollabProjects from '../components/work/collabProjects.js';
import Events from '../components/work/events.js';
import Navbar from '../components/navbar.js';
import Loader from '../components/loader.js';
import { Fade } from '@mui/material';
import { useEffect } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import Footer from '../components/footer.js';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

function Work({ commonData, dataLoaded, setDataLoaded, aboutRef, trusteesRef, workRef, teamRef, knowledgeRef, contactRef, workPageContent, setWorkPageContent, collabProjs, setCollabProjs, projects, setProjects, events, setEvents }) {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });
    if (!dataLoaded) {
      axios({
        url: "https://capi-web.stellate.sh",
        method: "post",
        data: {
          query: `
              query workData {
                ourWorkPage(id: "cG9zdDo3Ng==") {
                  workPageDescription {
                    description
                  }
                }
                projects {
                  nodes {
                    title
                    id
                    projectInformation {
                      description
                      image {
                        sourceUrl
                      }
                      dateOfPublication
                    }
                    report_links {
                      links
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    typesOfProduct {
                      nodes {
                        name
                      }
                    }
                  }
                }
                collaborativeProjects {
                  nodes {
                    title
                    projectInformation {
                      dateOfPublication
                      description
                      image {
                        sourceUrl
                      }
                    }
                    project_link {
                      link
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    typesOfProduct {
                      nodes {
                        name
                      }
                    }
                  }
                }
                events {
                  nodes {
                    title
                    id
                    eventInformation {
                      description
                      registrationLink
                      image {
                        sourceUrl
                      }
                      type
                      venue
                      publishtype
                      author
                      publishedon
                    }
                  }
                }
              }
            `,
        },
      }).then((result) => {
        let data = result.data.data; //Make a temp data variable for repeating information
        console.log("data ", data);
        setWorkPageContent(data.ourWorkPage.workPageDescription);
        setCollabProjs(data.collaborativeProjects);
        setProjects(data.projects);
        setEvents(data.events);

        setDataLoaded(true);
      });
    }
  }, []);

  const meta = {
    title: 'Our Work',
    description: '',
  };

  return (
    <>
      <DocumentMeta {...meta} />
      {!commonData || !dataLoaded ?
        <Loader />
        :
        <Fade in={true}>
          <div className={styles.view_container}>
            <Navbar
              navigation_array={[
                { text: 'About Us', linkTo: 'about', ref: aboutRef, isscroll: true },
                // {text: 'Trustees', linkTo: 'trustees', ref: trusteesRef, isscroll: false},
                { text: 'People', linkTo: 'people', ref: teamRef, isscroll: false },
                { text: 'Our Work', linkTo: 'work', ref: workRef, isscroll: false },
                { text: 'Knowledge Sharing', linkTo: 'sharing', ref: knowledgeRef, isscroll: true },
                { text: 'Get in Touch', linkTo: 'contact', ref: contactRef, isscroll: true }
              ]}

              social={commonData.cAPIInfo.socialMedia}

              aboutRef={aboutRef}
              trusteesRef={trusteesRef}
              workRef={workRef}
              teamRef={teamRef}
              knowledgeRef={knowledgeRef}
              contactRef={contactRef}
            />
            <PageTitle title={'Our Work'} />
            <div className={classNames('section', styles.description)} dangerouslySetInnerHTML={{ __html: workPageContent.description }}></div>
            <Projects projects={projects.nodes} />
            <CollabProjects projects={collabProjs.nodes} />
            <Events events={events.nodes} />
            <Footer data={commonData.cAPIInfo} />
          </div>
        </Fade>
      }
    </>
  );
}

export default Work;