import styles from './css-modules/trustees.module.css';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";

function Trustees({ data, trusteesRef }) {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      if (location.state && location.state._key && location.state._type) {
        if (location.state._type === 'trustees') {
          navigate(location.state._key, {
            state: { background: location },
          });
        }
      }
    }, []);

    return (
      <div ref={trusteesRef} className={'section'}>
        <h2 className={styles.title}>Trustees</h2>
        <div className={styles.container}>
          {data.length > 0 && data.map((trustee, index) => {
            return trustee.typesOfProduct.nodes.map((types, tIndex) => {
              return (
                <>
                  {
                    types?.name == 'Trustees' && <Link to={"trustees_" + trustee.title.toLowerCase().replaceAll(" ", "")} state={{ background: location }} key={trustee.title.replaceAll(" ", "_") + "index"} className={styles.trustee}>
                      <div style={{backgroundImage: `url(${trustee.trusteeDetails.image.sourceUrl})`}} className={styles.image}></div>
                      <div className={styles.details_container}>
                        <h3 className={styles.name}>{trustee.title}</h3>
                        <div className={styles.separator}></div>
                        <b className={styles.desigation}>{trustee.trusteeDetails.designation}</b>
                        <p className={styles.org}>{trustee.trusteeDetails.organization}</p>
                        <p className={styles.country}>{trustee.trusteeDetails.country}</p>
                      </div>
                    </Link>
                  } 
                </>
              )
            })
          })}
        </div>
      </div>
    );
  }
  
  export default Trustees;