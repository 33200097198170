import styles from './css-modules/modal.module.css';
import projectStyles from './css-modules/project_display.module.css';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Fade } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { AiFillCloseCircle } from "react-icons/ai";
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

function EventModal() {
    const navigate = useNavigate();
    const location = useLocation();

    const [data, setData] = useState(null);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });
        let locArr = location.pathname.split('/');
        let id = locArr[locArr.length - 1];
        axios({
            url: "https://capi-web.stellate.sh",
            method: "post",
            data: {
                query: `
                query workData {
                    event(id: "${id}") {
                        title
                        eventInformation {
                            description
                            registrationLink
                            image {
                            sourceUrl
                            }
                            type
                            venue
                            publishtype
                            author
                            publishedon
                        }
                    }
                }
            `,
            },
        }).then((result) => {
            let temp = result.data.data.event; //Make a temp data variable for repeating information
            setData(temp);
        });

        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = "fixed";
        document.body.style.overflow = "hidden";
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.position = "";
            document.body.style.top = "";
            window.scrollTo(0, parseInt(scrollY || "0") * -1);
            document.body.style.overflow = "unset";
            document.body.style.overflowX = "hidden";
        };

    }, []);

    const meta = {
        title: data ? data.title : '',
        description: '',
    };

    return (
        <>
            <DocumentMeta {...meta} />
            <Fade in={true}>
                <div className={styles.modal_container}>
                    <Link to='/work' className={styles.background_container}></Link>
                    <div className={styles.content_container}>
                        {!data ? <div className={styles.loading}><p>Loading Data...</p></div>
                            :
                            <Fade in={true}>
                                <div className={styles.flexed_event_modal}>
                                    <div className={styles.image_register}>
                                        <img src={data.eventInformation.image.sourceUrl} className={styles.event_image}></img>
                                        <a role="button" className={projectStyles.button} href={data.eventInformation.registrationLink} target="_blank">
                                            <p className={projectStyles.button_text}>{data.eventInformation.publishtype == 'Event' ? 'Register' : 'Click Here to Read'}</p>
                                        </a>
                                    </div>
                                    <AiFillCloseCircle className={styles.close_icon} onClick={() => navigate('/work')} />
                                    <div className={styles.event_description}>
                                        <div className={styles.event_desc} dangerouslySetInnerHTML={{ __html: data.eventInformation.description }}></div>
                                        <div className={styles.venue_container}>
                                            {data.eventInformation.publishtype == 'Event' ?
                                                <>
                                                    <p className={styles.event_desc}><b>Date/Venue: </b><span dangerouslySetInnerHTML={{ __html: data.eventInformation.venue.replace('\n', '<br/>') }}></span></p>
                                                    <p className={styles.event_type}><i>This is an {data.eventInformation.type} event</i></p>
                                                </>
                                                :
                                                <p className={styles.venue}>
                                                    <strong> Author: </strong>{data.eventInformation.author} <br /> <br />
                                                    <strong> Published On: </strong>{data.eventInformation.publishedon}
                                                </p>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        }
                    </div>
                </div>
            </Fade>
        </>
    );
}

export default EventModal;
