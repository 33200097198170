import styles from './css-modules/page_title.module.css';

function PageTitle({ title, subtitle, dop }) {
    return (
      <div className={styles.container}>
          <img src={'https://i0.wp.com/capi.org.in/wordpress/wp-content/uploads/2022/06/pexels-alesia-kozik-7182356-1-1.jpg'} alt="" className={styles.title_media}/>
          <div className={styles.title_container}>
            <h1 className={styles.title}>{title}</h1>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
            {dop && <p className={styles.dop}>{dop}</p>}
          </div>
          <div className={styles.background_container}></div>
      </div>
    );
  }
  
  export default PageTitle;