import { useEffect } from 'react';
import styles from './css-modules/redirect_page.module.css';
import { useLocation } from 'react-router-dom';

function RedirectPage() {
    const location = useLocation();

    useEffect(() => {
        let pathname = location.pathname;
        let link = pathname.split('/')[pathname.split('/').length - 1];
        link = link.replaceAll('%sl_rdrct', '/');
        window.location.replace(link);
    }, []);

    return (
      <div className={styles.container}>
          <p className={styles.text}>Redirecting...</p>
          <img className={styles.absolute_logo} alt="" src='https://res.cloudinary.com/capi/image/upload/v1655346509/capi_log_white_zkgpw9.png'></img>
      </div>
    );
  }
  
  export default RedirectPage;