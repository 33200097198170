import { useEffect, useRef, useState } from "react";
import {
  useLocation,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga4";

//import views below
import Home from './views/home';
import Work from './views/work';
import TrusteeModal from './views/trusteeModal.js';
import EventModal from './views/eventModal.js';
import ProjectPage from './views/project_page.js';
import RedirectPage from './views/redirect_page.js';
import OurTeam from './views/team_page.js';

import './App.css';

function App() {
  const location = useLocation();
  const background = location.state && location.state.background;
  const navigate = useNavigate();

  const [commonData, setCommonData] = useState(null);

  //Data for Home page below 
  const [aboutSecData, setAboutSecData] = useState(null);
  const [teamSecData, setTeamSecData] = useState(null);
  const [workSecData, setWorkSecData] = useState(null);
  const [knowledgeData, setKnowledgeData] = useState(null);
  const [donorsData, setDonorsData] = useState(null);
  const [quotes, setQuotes] = useState(null);


  //References for scrolling navigation
  const aboutRef = useRef(null);
  const trusteesRef = useRef(null);
  const workRef = useRef(null);
  const teamRef = useRef(null);
  const donorsRef = useRef(null);
  const knowledgeRef = useRef(null);
  const contactRef = useRef(null);

  //Data for work page below 
  const [workPageContent, setWorkPageContent] = useState(null);
  const [projects, setProjects] = useState(null);
  const [collabProjs, setCollabProjs] = useState(null);
  const [events, setEvents] = useState(null);
  const [homeDataLoaded, setHomeDataLoaded] = useState(false);
  const [workDataLoaded, setWorkDataLoaded] = useState(false);
  const [teamDataLoaded, setTeamDataLoaded] = useState(false);


  useEffect(() => {

    let pathname = location.pathname;

    if (pathname.includes("trustees_")) {
      navigate('/', {
        state: {
          _key: pathname.split("/")[pathname.split("/").length - 1],
          _type: 'trustees'
        },
      });
    }

    if (pathname.includes("event/")) {
      navigate('/work', {
        state: {
          _key: pathname.split("/")[pathname.split("/").length - 1],
          _type: 'event'
        },
      });
    }

    if (!commonData) {
      axios({
        url: "https://capi-web.stellate.sh",
        method: "post",
        data: {
          query: `
            query commonInfo {
              cAPIInfo(id: "cG9zdDozMw==") {
                title
                socialMedia {
                  instagram
                  linkedin
                  twitter
                  youtube
                  facebook
                  email
                }
                description {
                  address
                  description
                }
              }
              hideSections(id: "cG9zdDo5NA==") {
                hideSections {
                  quotes
                  donors
                }
              }
            }
          `,
        },
      }).then((result) => {
        setCommonData(result.data.data);
      });
    }
    ReactGA.initialize("G-SWF5ZVE66H");
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={
          <Home
            commonData={commonData}
            aboutSecData={aboutSecData}
            workSecData={workSecData}
            knowledgeData={knowledgeData}
            donorsData={donorsData}
            quotes={quotes}
            dataLoaded={homeDataLoaded}
            setAboutSecData={setAboutSecData}
            setWorkSecData={setWorkSecData}
            setKnowledgeData={setKnowledgeData}
            setDonorsData={setDonorsData}
            setQuotes={setQuotes}
            setDataLoaded={setHomeDataLoaded}

            aboutRef={aboutRef}
            trusteesRef={trusteesRef}
            workRef={workRef}
            knowledgeRef={knowledgeRef}
            contactRef={contactRef}
          />
        }>
          <Route path=":trusteeName" element={<TrusteeModal
            data={aboutSecData}
          />} />
        </Route>
        <Route path="work" element={
          <Work
            commonData={commonData}
            dataLoaded={workDataLoaded}
            setDataLoaded={setWorkDataLoaded}

            workPageContent={workPageContent}
            setWorkPageContent={setWorkPageContent}
            collabProjs={collabProjs}
            setCollabProjs={setCollabProjs}
            projects={projects}
            setProjects={setProjects}
            events={events}
            setEvents={setEvents}

            aboutRef={aboutRef}
            trusteesRef={trusteesRef}
            workRef={workRef}
            knowledgeRef={knowledgeRef}
            contactRef={contactRef}
          />
        }>
          <Route path="event/:eventName" element={<EventModal
            data={aboutSecData}
          />} />
        </Route>
        <Route path="/work/:id" element={<ProjectPage
          commonData={commonData}
        />} />
        <Route path='/external-link/:id' element={<RedirectPage />} />

        <Route path="people" element={
          <OurTeam
            commonData={commonData}
            teamSecData={teamSecData}
            dataLoaded={teamDataLoaded}
            setTeamSecData={setTeamSecData}
            setDataLoaded={setTeamDataLoaded}

            aboutRef={aboutRef}
            trusteesRef={trusteesRef}
            workRef={workRef}
            knowledgeRef={knowledgeRef}
            contactRef={contactRef}
          />
        }>
          <Route path=":trusteeName" element={<TrusteeModal
            data={teamSecData}
          />} />
        </Route>

      </Routes>
      {background && (
        <Routes>
          <Route path="/people/:trusteeName" element={<TrusteeModal
            data={teamSecData}
          />} />
        </Routes>
      )}
      {background && (
        <Routes>
          <Route path=":trusteeName" element={<TrusteeModal
            data={aboutSecData}
          />} />
        </Routes>
      )}
      {background && (
        <Routes>
          <Route path="/work/event/:eventName" element={<EventModal
            data={aboutSecData}
          />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
