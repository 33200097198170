import styles from './css-modules/quotes.module.css';
import Slider from "react-slick";
import { MdFormatQuote } from "react-icons/md";


const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5500,
  cssEase: "linear",
  customPaging: i => (
    <div className={'custom_dots'}>
    </div>
  )
};

function Quotes({ data }) {
    return (
      <>
        {data.length > 0 && 
          <div className={styles.container}>
            <MdFormatQuote className={styles.absolute_md}></MdFormatQuote>
            <Slider {...settings}>
              {data.map((quote, index) =>
                <div key={index} className={styles.quote_container}>
                  <div className={styles.quote_contain}>
                    <p className={styles.quote}>{quote.quote.quote}</p>
                    <p className={styles.quoter}>
                      {quote.quote.attributedTo}
                    </p>
                  </div>
                </div>
              )}
            </Slider>
          </div>
        }
      </>
    );
  }
  
  export default Quotes;