import styles from './css-modules/navbar.module.css';
import { FaTwitter, FaLinkedin, FaEnvelope, FaYoutube, FaInstagram } from "react-icons/fa";
import { gsap } from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import { BiMenuAltLeft } from "react-icons/bi";
import { Fade } from '@mui/material';
import { AiFillCloseCircle } from "react-icons/ai";

gsap.registerPlugin(ScrollToPlugin);

function Navbar({ navigation_array, social }) {
    const location = useLocation();
    const navigate = useNavigate();
    let sticky;

    const navbarRef = useRef();

    const [mobileNav, setMobileNav] = useState(false);

    function processLink(link) {
        window.open(link);
    }

    function processLinkSelf(linkTo) {
        if (mobileNav) {
            const scrollY = document.body.style.top;
            document.body.style.position = "";
            document.body.style.top = "";
            window.scrollTo(0, parseInt(scrollY || "0") * -1);
            document.body.style.overflow = "unset";
            document.body.style.overflowX = "hidden";
            setMobileNav(false);
        }
        if (location.pathname === '/') {
            navigate(`/${linkTo}`);
        } else if (linkTo === 'people') {
            navigate('/people');
        }
        else if (location.pathname === '/work') {
            if (linkTo !== 'work'){
                navigate('/', {
                    state: { from: '/work', linkTo: linkTo},
                });
            }
        }
        else {
            if (linkTo !== 'work'){
                navigate('/', {
                    state: { from: '/work', linkTo: linkTo},
                });
            }
            else {
                navigate('/work');
            }
        }
    }

    function processScrollClick(ref, linkTo) {
        if (mobileNav) {
            const scrollY = document.body.style.top;
            document.body.style.position = "";
            document.body.style.top = "";
            window.scrollTo(0, parseInt(scrollY || "0") * -1);
            document.body.style.overflow = "unset";
            document.body.style.overflowX = "hidden";
            setMobileNav(false);
        }
        if (location.pathname === '/') {
            let top = ref.current.getBoundingClientRect().top;
            let y = top + window.pageYOffset - 100;
            let anim_t = Math.round(Math.abs(top)*0.0008 * 100) / 100;
    
            gsap.to(window, {duration: anim_t, scrollTo: y});
        }
        else if (location.pathname === '/work') {
            if (linkTo !== 'work'){
                navigate('/', {
                    state: { from: '/work', linkTo: linkTo},
                });
            }
        }
        else {
            if (linkTo !== 'work'){
                navigate('/', {
                    state: { from: '/work', linkTo: linkTo},
                });
            }
            else {
                navigate('/work');
            }
        }
    }

    useEffect(() => {
        sticky = navbarRef.current.offsetTop;
        window.onscroll = function() {
            stickyScroll();
        }
    }, []);

    useEffect(() => {
        if (mobileNav) {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = "fixed";
            document.body.style.overflow = "hidden";
        }
    }, [mobileNav]);


    function closeMobileNav() {
        setMobileNav(false);
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
        document.body.style.overflow = "unset";
        document.body.style.overflowX = "hidden";
    }

    function stickyScroll() {
        if (navbarRef.current) {
            if (window.pageYOffset > sticky) {
                if (!navbarRef.current.classList.contains(styles.sticky_nav)) {
                    navbarRef.current.classList.add(styles.sticky_nav);
                }
            } else {
                navbarRef.current.classList.remove(styles.sticky_nav);
            }
        }
    }
    
    return (
      <nav ref={navbarRef} className={styles.navbar_main}>
          <img onClick={() => navigate('/')} src='https://res.cloudinary.com/capi/image/upload/v1655342459/CAPI_Logo_3_Color_1_1_zrgmw2.png' alt='Centre for Asian Philanthropy India' className={styles.nav_logo}></img>
          <div className={styles.desk_nav_container}>
                {navigation_array && navigation_array.map((navItem, index) => {
                    return (<div className={styles.nav_link_container} key={index + 'nav_link'}>
                        {(navItem.isscroll) ?
                        <div onClick={() => processScrollClick(navItem.ref, navItem.linkTo)}>
                            <p className={styles.nav_text}>{navItem.text}</p>
                        </div>
                            :
                            <div onClick={() => processLinkSelf(navItem.linkTo)}>
                            <p className={styles.nav_text}>{navItem.text}</p>
                        </div>
                    }
                    </div>
                )})}
          </div>
          <div className={styles.social_container}>
                {social.linkedin && <FaLinkedin className={styles.social_icon} onClick={(e) => processLink(social.linkedin)}/>}
                {social.twitter && <FaTwitter className={styles.social_icon} onClick={(e) => processLink(social.twitter)}/>}
                {social.instagram && <FaInstagram className={styles.social_icon} onClick={(e) => processLink(social.instagram)}/>}
                {social.youtube && <FaYoutube className={styles.social_icon} onClick={(e) => processLink(social.youtube)}/>}
                {social.email && <FaEnvelope className={styles.social_icon} onClick={(e) => processLink(social.email)}/>}
          </div>

          <BiMenuAltLeft className={styles.mobile_nav_icon} onClick={() => setMobileNav(true)}></BiMenuAltLeft>

          {mobileNav && <Fade in={true}><div className={styles.mobile_nav_container}>
            {navigation_array && navigation_array.map((navItem, index) => {
                return (<div className={styles.nav_link_container} key={index + 'nav_link'}>
                    {(navItem.isscroll) ?
                    <div onClick={() => processScrollClick(navItem.ref, navItem.linkTo)}>
                        <p className={styles.mob_nav_text}>{navItem.text}</p>
                    </div>
                        :
                        <div onClick={() => processLinkSelf(navItem.linkTo)}>
                        <p className={styles.mob_nav_text}>{navItem.text}</p>
                    </div>
                }
                </div>
            )})}
            <AiFillCloseCircle className={styles.close_icon} onClick={() => closeMobileNav()}/>
            <div className={styles.social_container_mob}>
                {social.linkedin && <FaLinkedin className={styles.social_icon_mob} onClick={(e) => processLink(social.linkedin)}/>}
                {social.twitter && <FaTwitter className={styles.social_icon_mob} onClick={(e) => processLink(social.twitter)}/>}
                {social.instagram && <FaInstagram className={styles.social_icon_mob} onClick={(e) => processLink(social.instagram)}/>}
                {social.youtube && <FaYoutube className={styles.social_icon_mob} onClick={(e) => processLink(social.youtube)}/>}
                {social.email && <FaEnvelope className={styles.social_icon_mob} onClick={(e) => processLink(social.email)}/>}
            </div>
          </div></Fade>}
      </nav>
    );
  }
  
  export default Navbar;