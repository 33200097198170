import styles from './css-modules/contact.module.css';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Fade } from '@mui/material';
import * as emailjs from 'emailjs-com';

function Contact({ contactRef }) {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [organization, setOrganization] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [disableInputs, setDisableInputs] = useState(false);
    const [incompleteErr, setIncompleteErr] = useState(false);
    const [successFul, setSuccessFul] = useState(false);
    const [mailChimpReq, setMailChimpReq] = useState(null);
    const [subscribed, setSubscribed] = useState(false);

    function validateEmail(email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    function setValueState(event, id) {
      if (id === "name") {
        setName(event.target.value);
      } else if (id === "organization") {
        setOrganization(event.target.value);
      } else if (id === "email") {
        setEmail(event.target.value);
      } else if (id === "subject") {
        setSubject(event.target.value);
      } else if (id === "message") {
        setMessage(event.target.value);
      }
    }

    function resetForm() {
      setOrganization('');
      setEmail('');
      setName('');
      setSubject('');
      setMessage('');
    }

    function submitContactForm() {
      if (email === "" || organization === "" || name === "" || subject === "" || message === "") {
        setIncompleteErr(true);
        setSuccessFul(false);
      } else {
        setDisableInputs(true);
        if (validateEmail(email)) {
          let req = {
            email: email,
            name: name,
            organization: organization,
            subscriptionStatus: subscribed ? 'subscribed' : 'unsubscribed',
          };
          setMailChimpReq(req);
        } else {
          setIncompleteErr(true);
          setSuccessFul(false);
        }
      }
    }

    useEffect(() => {
      mailChimpReq && 
        axios
          .post(
            "https://capi-express.herokuapp.com/api/addMember",
            mailChimpReq
          )
          .then(function (response) {
            if (response.data.statusCode === 200) {
                sendEmail({
                  from_name: name,
                  email: email,
                  subject: subject, 
                  message: message,
                  organization: organization,
                });
            } else if (response.data.statusCode === 400) {
                sendEmail({
                  from_name: name,
                  email: email,
                  subject: subject, 
                  message: message,
                  organization: organization,
                });
            } else {
              setDisableInputs(false);
              setSuccessFul(false);
              setIncompleteErr(true);
            }
          });
    }, [mailChimpReq]);

    useEffect(() => {
      emailjs.init('JnIU4pzxXPBNyCK_I');
    }, []);

    function sendEmail(templateParams) {
      emailjs.send(
        'service_rgk8vzx',
        'template_3rk7k4r',
        templateParams,
        'JnIU4pzxXPBNyCK_I',
      ).then(function(response) {
            resetForm();
            setIncompleteErr(false);
            setSuccessFul(true);
            setDisableInputs(false);
      }, function(error) {
            setIncompleteErr(true);
            setSuccessFul(false);
            setDisableInputs(false);
      });
    }

    return (
      <div ref={contactRef} className={classNames('section', styles.relative_container)}>
        <img src='https://res.cloudinary.com/capi/image/upload/v1655938657/capi_log_enboci.png' className={styles.absolute_logo}></img>
        <img src='https://res.cloudinary.com/capi/image/upload/v1655938657/capi_log_enboci.png' className={styles.absolute_logo_small}></img>
        <h2 className={styles.title}>Get in Touch</h2>
        <p className={styles.sub_title}>Send us a message and we will get back to you</p>

        <div className={styles.form_container}>
          <div className={styles.input_row}>
            <div className={styles.half_input}>
              <TextField disabled={disableInputs} required label="Name" variant="standard" value={name} onChange={(event) => setValueState(event, "name")} />
            </div>
            <div className={styles.half_input}>
              <TextField disabled={disableInputs} required label="Organization" variant="standard" value={organization} onChange={(event) => setValueState(event, "organization")} />
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.half_input}>
              <TextField disabled={disableInputs} required label="Email" variant="standard" value={email} onChange={(event) => setValueState(event, "email")} />
            </div>
            <div className={styles.half_input}>
              <TextField disabled={disableInputs} required label="Subject" variant="standard" value={subject} onChange={(event) => setValueState(event, "subject")} />
            </div>
          </div>
          <TextField disabled={disableInputs} required label="Message" variant="standard" value={message} multiline rows={4} onChange={(event) => setValueState(event, "message")}/>

          <div className={styles.checkbox_cont}>
            <FormControlLabel control={<Checkbox value={subscribed} onChange={() => setSubscribed(!subscribed)} />} label="Receive updates about our future events and projects" />
          </div>

          <div className={styles.message}>
            {incompleteErr && (
                  <Fade in={true}>
                    <p className={styles.status}>
                      We could not process your request. Please review the information/email address and try again
                    </p>
                  </Fade>
                )}
                {successFul && (
                  <Fade in={true}>
                    <p className={styles.status}>
                      Thank you for reaching out to us. We'll get back to you shortly.  
                    </p>
                  </Fade>
            )}
          </div>

          <button className={'button additional_margin_button'} onClick={() => submitContactForm()}>
              <div className={'button_animator'}>
                  <div className={'button_animator_inner'}></div>
              </div>
              <p className={'button_text'}>Submit</p>
          </button>
        </div>
      </div>
    );
  }
  
  export default Contact;