import styles from './css-modules/landing.module.css';
import { useRef, useState, useEffect } from 'react';
import { Fade } from '@mui/material';

function Landing({ data }) {

    let videoRef = useRef();

    const [videoLoaded, setVideoLoaded] = useState(false);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('loadeddata', (event) => {
                setVideoLoaded(true);
            });
        }
    }, [videoRef.current]);

    return (
      <div className={styles.container}>
          <div className={styles.text_container}>
            <img className={styles.upper_fadedLogo} alt="" src='https://res.cloudinary.com/capi/image/upload/v1655346509/capi_log_white_zkgpw9.png'></img>
            <div className={styles.content_container}>
              <h1 className={styles.heading}>{data.title}</h1>
              <div className={styles.dot_separator}>
                  <div className={styles.dot}></div>
                  <div className={styles.dot}></div>
                  <div className={styles.dot}></div>
                  <div className={styles.dot}></div>
              </div>
              <p className={styles.sub_title}>{data.description.description}</p>
            </div>
            <img className={styles.lower_fadedLogo} alt="" src='https://res.cloudinary.com/capi/image/upload/v1655346509/capi_log_white_zkgpw9.png'></img>
          </div>
          <div className={styles.video_container}>
            <Fade in={!videoLoaded}>
                <img className={styles.video_image} alt="" src='https://res.cloudinary.com/capi/video/upload/v1655339593/main_video_tlis5n.png'></img>
            </Fade>
            <video ref={videoRef} className={styles.video} autoPlay muted loop>
              <source src="https://res.cloudinary.com/capi/video/upload/v1655339593/main_video_tlis5n.mp4" type="video/mp4"/>
            </video>
          </div>
      </div>
    );
  }
  
  export default Landing;