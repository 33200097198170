import styles from './css-modules/views.module.css';
import projectStyles from './css-modules/project_display.module.css';
import PageTitle from '../components/work/page_title.js';
import Navbar from '../components/navbar.js';
import Loader from '../components/loader.js';
import ReportModal from '../components/work/report_modal.js';
import { Fade } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Footer from '../components/footer.js';
import YouTube from 'react-youtube-embed';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";




function Work( { commonData, aboutRef, trusteesRef, workRef, teamRef, knowledgeRef, contactRef }) {

    const [data, setData] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [Yt_ID, setYt_ID] = useState(null);
    const [linkButtons, setLinkButtons] = useState(null);
    const [reportModal, openReportModal] = useState(false);
    const [currReportData, setCurrReportData] = useState(null);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });
        window.scrollTo(0,0);
        let locArr = location.pathname.split('/');
        let id = locArr[locArr.length - 1];
        axios({
            url: "https://capi-web.stellate.sh",
            method: "post",
            data: {
              query: `
                query workData {
                    project(id: "${id}") {
                        title
                        id
                        projectInformation {
                            description
                            image {
                            sourceUrl
                            }
                            dateOfPublication
                            imageDetails
                        }
                        report_links {
                            links
                        }
                        categories {
                            nodes {
                            name
                            }
                        }
                        typesOfProduct {
                            nodes {
                            name
                            }
                        }
                        youtubeUrl {
                            url
                        }
                    }
                }
              `,
            },
          }).then((result) => {
            let temp = result.data.data.project; //Make a temp data variable for repeating information
            if (temp.youtubeUrl.url) {
                let parseID = parseYoutubeURL(temp.youtubeUrl.url);
                setYt_ID(parseID);
            }

            if (temp.report_links.links && temp.report_links.links !== '') {
                let linkButtons = temp.report_links.links.split(' - ');
                let linkButtonObjArr = []; 
                for (let i = 0; i < linkButtons.length; i++) {
                  let splitValues = linkButtons[i].split(',');
                  linkButtonObjArr.push({link: splitValues[0], title: splitValues[1]});
                }
                setLinkButtons(linkButtonObjArr);
            }
            setData(temp);
          });

    }, []);

    function parseYoutubeURL(url) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length === 11) {
          return match[2];
        } else {
          console.log('error');
        }
    }

    function processButtonClick(title, link) {
        let reportData = {};
        if (!title.replaceAll(' ', '').toLowerCase().includes('pressrelease')) {
          reportData.type = 'Other';
          if (title.replaceAll(' ', '').toLowerCase().includes('report')) {
            reportData.type = 'Report';
          }
          if (title.replaceAll(' ', '').toLowerCase().includes('executivesummary')) {
            reportData.type = 'Executive Summary';
          }
          reportData.link = link;
          reportData.title = data.title;
          setCurrReportData(reportData);
          openReportModal(true);
        }
        else {
         navigate('/external-link/' + link.replaceAll('/', '%0_sl_rdrct'));
          // window.open(link);
        }
    }

    const meta = {
      title: data ? data.title : '',
      description: '',
    };

    return (
      <>
      <DocumentMeta {...meta}/>
      {!commonData || !data ? 
        <Loader/>
        :
        <Fade in={true}>
          <div className={styles.view_container}>
            <Navbar
              navigation_array = {[
                {text: 'About Us', linkTo: 'about', ref: aboutRef, isscroll: true},
                  // {text: 'Trustees', linkTo: 'trustees', ref: trusteesRef, isscroll: false},
                  {text: 'People', linkTo: 'people', ref: teamRef, isscroll: false},
                  { text: 'Our Work', linkTo: 'work', ref: workRef, isscroll: false },
                  {text: 'Knowledge Sharing', linkTo: 'sharing', ref: knowledgeRef, isscroll: true},
                  {text: 'Get in Touch', linkTo: 'contact', ref: contactRef, isscroll: true}
              ]}

              social={commonData.cAPIInfo.socialMedia}

              aboutRef={aboutRef}
              trusteesRef={trusteesRef}
              workRef={workRef}
              teamRef={teamRef}
              knowledgeRef={knowledgeRef}
              contactRef={contactRef}
            />
            <PageTitle 
                title={data.title} 
                subtitle={data.categories.nodes.length > 0 ? <span><b>{data.categories.nodes.length > 1 ? 'Sectors' : 'Sector'}: </b>{data.categories.nodes.map((cat, index) => {
                    return <span key={'categ' + index}>{cat.name} {index < data.categories.length - 1 ? <>,</> : <></>}</span>
                })}</span> : data.title=='Gigs for Good'?'Conversations with Social Change Leaders':<></>}
                dop={data.projectInformation.dateOfPublication}
            />
            <div className='section'>
                <div className={projectStyles.container}>
                    <div className={projectStyles.image_buttons_container}>
                    <img src={data.projectInformation.image.sourceUrl} className={projectStyles.project_image_new}></img>
                    <div className={projectStyles.imageDetails} dangerouslySetInnerHTML={{__html: data.projectInformation.imageDetails}}></div>
                    {
                      data.typesOfProduct.nodes.map((gigs) => {
                        return (
                          gigs.name !== "gigsforgood" && <div className={styles.buttons_container}>
                            {linkButtons && linkButtons.length > 0 && linkButtons.map((buttonData, index) => {
                                return (
                                <a href={buttonData.link} key={index} target='_blank' rel="noreferrer" className={projectStyles.button}> 
                                    <p className={projectStyles.button_text}>{buttonData.title}</p>
                                </a>
                                // <div key={index} role="button" className={projectStyles.button} onClick={() => processButtonClick(buttonData.title ,buttonData.link)}> 
                                //     <p className={projectStyles.button_text}>{buttonData.title}</p>
                                // </div>
                                )
                            })}
                        </div>
                        )
                      })
                      }
                        
                    </div>
                    <div className={projectStyles.description_container}>
                        <div className={projectStyles.description} dangerouslySetInnerHTML={{__html: data.projectInformation.description}}></div>
                        {Yt_ID && <div className={projectStyles.yt_centered}>
                            <YouTube id={Yt_ID}/>
                        </div>}
                    </div>
                </div>
            </div>
            <Footer data={commonData.cAPIInfo}/>

            {reportModal && <ReportModal data={currReportData} openReportModal={openReportModal}/>}
          </div>
        </Fade>
        }
      </>
    );
  }
  
  export default Work;