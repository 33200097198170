import styles from './css-modules/views.module.css';
import PageTitle from '../components/work/page_title.js';
import Navbar from '../components/navbar.js';
import Loader from '../components/loader.js';
import { Fade } from '@mui/material';
import { useEffect } from 'react';
import axios from 'axios';
import Footer from '../components/footer.js';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import Team from '../components/team/team.js';
import { gsap } from 'gsap';

function OurTeam({commonData, teamSecData, setTeamSecData, dataLoaded, setDataLoaded, aboutRef, trusteesRef, workRef, teamRef, knowledgeRef, contactRef}) {

   const location = useLocation();

     useEffect(() => {
      window.scrollTo(0,0);
      ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });
      if (!dataLoaded) {
        axios({
          url: "https://capi-web.stellate.sh",
          method: "post",
          data: {
            query: `
              query homeData {
                trustees {
                  nodes {
                    trusteeDetails {
                      organization
                      popupDescription
                      designation
                      country
                      image {
                        sourceUrl
                      }
                    }
                    title
                    typesOfProduct {
                      nodes {
                        name
                      }
                    }
                  }
                }
              }
            `,
          },
        }).then((result) => {
          let data = result.data.data; //Make a temp data variable for repeating information
          let tempAboutData = {};
          tempAboutData.trustees = data.trustees.nodes;
          setTeamSecData(tempAboutData);
          setDataLoaded(true);
        });
      }
     }, []);
  
  useEffect(() => {
      if (location.state && location.state.linkTo && dataLoaded && commonData) {
        let ref;
        ref = teamRef;
        let top = ref.current.getBoundingClientRect().top;
        let y = top + window.pageYOffset - 100;
        let anim_t = Math.round(Math.abs(top)*0.0008 * 100) / 100;

        gsap.to(window, {duration: anim_t, scrollTo: y}).delay(0.4);
      }
    }, [location]);

    const meta = {
      title: 'People',
      description: '',
    };

    return (
      <>
       <DocumentMeta {...meta}/>
      {!commonData || !dataLoaded ? 
        <Loader/>
        :
        <Fade in={true}>
          <div className={styles.view_container}>
            <Navbar
              navigation_array = {[
                {text: 'About Us', linkTo: 'about', ref: aboutRef, isscroll: true},
                  // {text: 'Trustees', linkTo: 'trustees', ref: trusteesRef, isscroll: false},
                  {text: 'People', linkTo: 'people', ref: teamRef, isscroll: false},
                  { text: 'Our Work', linkTo: 'work', ref: workRef, isscroll: false },
                  {text: 'Knowledge Sharing', linkTo: 'sharing', ref: knowledgeRef, isscroll: true},
                  {text: 'Get in Touch', linkTo: 'contact', ref: contactRef, isscroll: true}
              ]}

              social={commonData.cAPIInfo.socialMedia}

              aboutRef={aboutRef}
              trusteesRef={trusteesRef}
              workRef={workRef}
              teamRef={teamRef}
              knowledgeRef={knowledgeRef}
              contactRef={contactRef}
            />
              <PageTitle title={'People'} />
              
           
           <Team
                data={teamSecData}
                teamRef={teamRef}
              />

            <Footer data={commonData.cAPIInfo}/>
          </div>
        </Fade>
      }
      </>
    );
  }
  
  export default OurTeam;