import styles from './css-modules/knowledge.module.css';
import classNames from 'classnames';

function Donors({ data, knowledgeRef }) {
  function openWebsite(link) {
    if (link) {
      window.open(link);
    }
  }

  return (
    <div ref={knowledgeRef} className={'section'}>
      <h2 className={styles.title}>Donors</h2>
      <div className={styles.container}>
        <div className={styles.desc} dangerouslySetInnerHTML={{ __html: data.desc }}></div>
        <div className={styles.partner_container}>
          {data.partners.length > 0 && data.partners.map((partner, index) => {
            return <div key={'partner' + index} onClick={() => openWebsite(partner.partnerData.partnerWebsite)} className={classNames(styles.partner_logo_container, partner.partnerData.partnerWebsite && styles.cursor)}>
              <img src={partner.partnerData.partnerLogo.sourceUrl}></img>
              <p><b>{partner.partnerData.partnerName}</b></p>
            </div>
          })}
        </div>
      </div>
    </div>
  );
}

export default Donors;