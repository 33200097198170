import styles from './css-modules/loader.module.css';
import { ThreeDots } from  'react-loader-spinner'

function Loader() {
    return (
      <div className={styles.container}>
        <ThreeDots
            height="50"
            width="50"
            color='#134835'
            ariaLabel='loading'
        />
      </div>
    );
  }
  
  export default Loader;