import styles from './css-modules/events.module.css';
import { useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

function Events({ events }) {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state && location.state._key && location.state._type) {
            if (location.state._type === 'event') {
                navigate('event/' + location.state._key, {
                    state: { background: location },
                });
            }
        }
    }, []);

    return (
        <div className={'section'}>
            <h2>Events & Media</h2>
            <div className={styles.flexed_projects}>
                {events.length > 0 && events.map((event, index) => {
                    return <div key={'events_' + index} className={styles.container}>
                        <div className={styles.image_container}>
                            <img src={event.eventInformation.image.sourceUrl} className={styles.title_image}></img>
                            {
                                event.eventInformation.publishtype == 'Event' ?
                                    <p className={styles.etype}>{event.eventInformation.type}</p>
                                    :
                                    null
                            }

                        </div>
                        <div className={styles.content_container}>
                            <h3 className={styles.title}>{event.title}</h3>
                            {
                                event.eventInformation.publishtype == 'Event' ?
                                    <p dangerouslySetInnerHTML={{ __html: event.eventInformation.venue.replace('\n', '<br/>') }} className={styles.venue}></p>
                                    :
                                    <p className={styles.venue}>
                                        <strong> Author: </strong>{event.eventInformation.author} <br />
                                        <strong> Published On: </strong>{event.eventInformation.publishedon}
                                    </p>
                            }


                            <div className={styles.buttons_container}>
                                {event.eventInformation.publishtype == 'Event' ?
                                    <Link to={"event/" + event.id} state={{ background: location }} className={'button'}>
                                        <div className={'button_animator'}>
                                            <div className={'button_animator_inner'}></div>
                                        </div>
                                        <p className={'button_text'}>Register</p>
                                    </Link> :
                                    <a href={event.eventInformation.registrationLink} target="_blank" className={'button left_margined_button different_color'}>
                                        <div className={'button_animator'}>
                                            <div className={'button_animator_inner'}></div>
                                        </div>
                                        <p className={'button_text'}>Read More</p>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
}

export default Events;