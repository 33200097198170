import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import styles from './css-modules/views.module.css';
import Navbar from '../components/navbar.js';
import Landing from '../components/landing.js';
import DescSection from '../components/home/desc_section.js';
import Contact from '../components/home/contact.js';
import Quotes from '../components/home/quotes.js';
import KnowledgeSharing from '../components/home/knowledge.js';
import Trustees from '../components/home/trustees.js';
import Footer from '../components/footer.js';
import Loader from '../components/loader.js';
import { Fade } from '@mui/material';
import { gsap } from 'gsap';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";
import Donors from '../components/home/doners.js';

function Home({ commonData, aboutSecData, workSecData, knowledgeData, donorsData, quotes, dataLoaded, setAboutSecData, setWorkSecData, setKnowledgeData, setDonorsData, setQuotes, setDataLoaded,
  aboutRef, trusteesRef, workRef, teamRef, knowledgeRef, donorsRef, contactRef
}) {

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });
    if (!dataLoaded) {
      axios({
        url: "https://capi-web.stellate.sh",
        method: "post",
        data: {
          query: `
              query homeData {
                allHomePageSections {
                  nodes {
                    title
                    data {
                      bannerImage {
                        sourceUrl
                      }
                      paragraph
                      title
                    }
                  }
                }
                quotes {
                  nodes {
                    quote {
                      quote
                      attributedTo
                    }
                  }
                }
                partners {
                  nodes {
                    partnerData {
                      partnerName
                      partnerWebsite
                      partnerLogo {
                        sourceUrl
                      }
                    }
                  }
                }
                trustees {
                  nodes {
                    trusteeDetails {
                      organization
                      popupDescription
                      designation
                      country
                      image {
                        sourceUrl
                      }
                    }
                    title
                    typesOfProduct {
                      nodes {
                        name
                      }
                    }
                  }
                }
              }
            `,
        },
      }).then((result) => {
        let data = result.data.data; //Make a temp data variable for repeating information

        //Create an object that combines the data for Knowledge Sharing paragraph and partner logos
        let tempKnowledgeData = {};
        tempKnowledgeData.desc = data.allHomePageSections.nodes.filter((obj) => obj.title === 'Knowledge Sharing')[0].data.paragraph;
        tempKnowledgeData.partners = data.partners.nodes.reverse();
        setKnowledgeData(tempKnowledgeData);

        let tempDonerData = {};
        tempDonerData.desc = data.allHomePageSections.nodes.filter((obj) => obj.title === 'Donors')[0].data.paragraph;
        tempDonerData.partners = data.partners.nodes.reverse();
        setDonorsData(tempDonerData);

        //Create an object that combines the data for About Section description and trustees
        let tempAboutData = {};
        tempAboutData.data = data.allHomePageSections.nodes.filter((obj) => obj.title === 'About')[0].data;
        tempAboutData.trustees = data.trustees.nodes;
        setAboutSecData(tempAboutData);

        setWorkSecData(data.allHomePageSections.nodes.filter((obj) => obj.title === 'Work')[0]);

        setQuotes(data.quotes.nodes);

        setDataLoaded(true);
      });
    }
  }, []);


  useEffect(() => {
    if (location.state && location.state.linkTo && dataLoaded && commonData) {
      let ref;
      if (location.state.linkTo === 'about') {
        ref = aboutRef;
      }
      else if (location.state.linkTo === 'trustees') {
        ref = trusteesRef;
      }
      else if (location.state.linkTo === 'people') {
        ref = teamRef;
      }
      else if (location.state.linkTo === 'about') {
        ref = aboutRef;
      }
      else if (location.state.linkTo === 'contact') {
        ref = contactRef;
      }
      else if (location.state.linkTo === 'sharing') {
        ref = knowledgeRef;
      }
      let top = ref.current.getBoundingClientRect().top;
      let y = top + window.pageYOffset - 100;
      let anim_t = Math.round(Math.abs(top) * 0.0008 * 100) / 100;

      gsap.to(window, { duration: anim_t, scrollTo: y }).delay(0.4);
    }
  }, [location]);

  const meta = {
    title: 'Center for Asian Philanthropy India',
    description: '',
  };

  return (
    <>
      <DocumentMeta {...meta} />
      {!commonData || !dataLoaded ?
        <Loader />
        :
        <Fade in={true}>
          <div className={styles.view_container}>
            <Navbar
              navigation_array={[
                { text: 'About Us', linkTo: 'about', ref: aboutRef, isscroll: true },
                // {text: 'Trustees', linkTo: 'trustees', ref: trusteesRef, isscroll: false},
                { text: 'People', linkTo: 'people', ref: teamRef, isscroll: false },
                { text: 'Our Work', linkTo: 'work', ref: workRef, isscroll: false },
                { text: 'Knowledge Sharing', linkTo: 'sharing', ref: knowledgeRef, isscroll: true },
                { text: 'Get in Touch', linkTo: 'contact', ref: contactRef, isscroll: true }
              ]}

              social={commonData.cAPIInfo.socialMedia}

              aboutRef={aboutRef}
              trusteesRef={trusteesRef}
              workRef={workRef}
              teamRef={teamRef}
              knowledgeRef={knowledgeRef}
              contactRef={contactRef}
            />
            <Landing data={commonData.cAPIInfo} />
            <DescSection
              data={aboutSecData}
              orientation='default'
              background='default'
              buttonData={null}
              _ref={aboutRef}
            />

            <Trustees
              data={aboutSecData.trustees}
              trusteesRef={trusteesRef}
            />

            <DescSection
              data={workSecData}
              orientation='reverse'
              background='gray'
              buttonData={{
                text: 'Learn More',
                linkTo: 'work'
              }}
              _ref={workRef}
            />

            {commonData.hideSections.hideSections.donors === 'False' && <Donors
              data={donorsData}
              donorsRef={donorsRef}
            />}

            <KnowledgeSharing
              data={knowledgeData}
              knowledgeRef={knowledgeRef}
            />

            {commonData.hideSections.hideSections.quotes === 'False' && <Quotes
              data={quotes}
            />}

            <Contact
              contactRef={contactRef}
            />

            <Footer data={commonData.cAPIInfo} />
          </div>
        </Fade>
      }
    </>
  );
}

export default Home;