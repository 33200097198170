import styles from "./css-modules/projects.module.css";
import { Link } from "react-router-dom";

function Projects({ projects }) {
  return (
    <>
    
      <div className={"section"}>
        <h2 className={styles.heading}>CAPI Publications</h2>
        <h2>Reports/Case Studies</h2>
        <div className={styles.flexed_projects}>
          {projects.length > 0 &&
            projects.map((project, index) => {
              return project.typesOfProduct.nodes.map((types, tIndex) => {
                return (
                  <>
                    {types?.name == "Report" && (
                      <div
                        key={"project_" + index}
                        className={styles.container}
                      >
                        <img
                          src={project.projectInformation.image.sourceUrl}
                          className={styles.title_image}
                        ></img>
                        <div className={styles.content_container}>
                          <h3 className={styles.title}>{project.title}</h3>
                          <p className={styles.dob}>
                            {project.projectInformation.dateOfPublication}
                          </p>

                          {project.categories.nodes.length > 0 && (
                            <p className={styles.sectors}>
                              <b>
                                {project.categories.nodes.length > 1
                                  ? "Sectors"
                                  : "Sector"}
                              </b>{" "}
                              {project.categories.nodes.map((cat, index) => {
                                return (
                                  <span key={"categ" + index}>
                                    {cat.name}{" "}
                                    {index < project.categories.length - 1 ? (
                                      <>,</>
                                    ) : (
                                      <></>
                                    )}
                                  </span>
                                );
                              })}
                            </p>
                          )}

                          <Link to={project.id} className={"button"}>
                            <div className={"button_animator"}>
                              <div className={"button_animator_inner"}></div>
                            </div>
                            <p className={"button_text"}>Read More</p>
                          </Link>
                        </div>
                      </div>
                    )}
                  </>
                );
              });
            })}
        </div>
      </div>
      <div className={"section"}>
        <h2>Gigs for Good</h2>
        <h3>Conversations with Social Change Leaders</h3>
        <div className={styles.flexed_projects}>
          {projects.length > 0 &&
            projects.map((project, index) => {
              return project.typesOfProduct.nodes.map((types, tIndex) => {
                return (
                  <>
                    {types?.name == "gigsforgood" && (
                      <div
                        key={"project_" + index}
                        className={styles.container}
                      >
                        <img
                          src={project.projectInformation.image.sourceUrl}
                          className={styles.title_image}
                        ></img>
                        <div className={styles.content_container}>
                          <h3 className={styles.title}>{project.title}</h3>
                          <p className={styles.dob}>
                            {project.projectInformation.dateOfPublication}
                          </p>

                          {project.categories.nodes.length > 0 && (
                            <p className={styles.sectors}>
                              <b>
                                {project.categories.nodes.length > 1
                                  ? "Sectors"
                                  : "Sector"}
                              </b>{" "}
                              {project.categories.nodes.map((cat, index) => {
                                return (
                                  <span key={"categ" + index}>
                                    {cat.name}{" "}
                                    {index < project.categories.length - 1 ? (
                                      <>,</>
                                    ) : (
                                      <></>
                                    )}
                                  </span>
                                );
                              })}
                            </p>
                          )}

                          <Link to={project.id} className={"button"}>
                            <div className={"button_animator"}>
                              <div className={"button_animator_inner"}></div>
                            </div>
                            <p className={"button_text"}>Read More</p>
                          </Link>
                        </div>
                      </div>
                    )}
                  </>
                );
              });
            })}
        </div>
      </div>
    </>
  );
}

export default Projects;
