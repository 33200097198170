import styles from './css-modules/footer.module.css';
import { FaFacebookF, FaTwitter, FaLinkedin, FaEnvelope, FaYoutube, FaInstagram } from "react-icons/fa";

function Footer({ data }) {
    function processLink(link) {
      window.open(link);
    }

    return (
      <footer className={styles.container}>
          <div className={styles.flexed_content}>
            <img src='https://res.cloudinary.com/capi/image/upload/v1655342459/CAPI_Logo_3_Color_1_1_zrgmw2.png' alt='Centre for Asian Philanthropy India' className={styles.nav_logo}></img>
            <div className={styles.info_container}>
                <div className={styles.text_container}>
                  <h1 className={styles.heading}>{data.title}</h1>
                  <p dangerouslySetInnerHTML={{__html: data.description.address.replace('\n', '<br/>')}} className={styles.address}></p>
                </div>

                <div className={styles.social_container}>
                  {data.socialMedia.linkedin && <FaLinkedin className={styles.social_icon} onClick={(e) => processLink(data.socialMedia.linkedin)}/>}
                  {data.socialMedia.twitter && <FaTwitter className={styles.social_icon} onClick={(e) => processLink(data.socialMedia.twitter)}/>}
                  {data.socialMedia.instagram && <FaInstagram className={styles.social_icon} onClick={(e) => processLink(data.socialMedia.instagram)}/>}
                  {data.socialMedia.youtube && <FaYoutube className={styles.social_icon} onClick={(e) => processLink(data.socialMedia.youtube)}/>}
                  {data.socialMedia.email && <FaEnvelope className={styles.social_icon} onClick={(e) => processLink(data.socialMedia.email)}/>}
                </div>
            </div>
          </div>
          <div>
              <p className={styles.copyright_disclaimer}>{new Date().getFullYear()} © {data.title}, All Rights Reserved</p>
          </div>
      </footer>
    );
  }
  
  export default Footer;